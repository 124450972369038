:root {
    --blue:#0A8A95;
    --light-blue:#ECF3F4;
    --dark-grey:#1B2D49;
    --light-grey:rgba(27, 45, 73, 0.8);
    --warning:#F64006;
  }
  
  /*
  body {
    font-family: 'Montserrat', sans-serif !important;
    color: var(--dark-grey) !important;
    background: #f5f9fa;
  }
  */
  
  .album-upload-background {
    background: #f5f9fa;
    margin-top: -30px;
    min-height: calc(100vh - 104px);
}
.create-album-page {  
  margin-left: 275px;
}


@media (max-width:1000px) {
  .create-album-page {
    margin-left: 0px;
  }
}

  .my_custom_container{
    max-width: 1250px;
    margin: 0px auto;
    /* border-radius: 12px; */
    /* min-height: 550px; */
  }
  .my_custom_container_stepone{
    width: 100%;
    max-width: 1250px;
    margin: 0px auto;
    /* border-radius: 12px; */
    /* min-height: 550px; */
  }
  
  .album_container {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  
  .my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .mx-20 {
    margin-left: 20px;
    margin-right: 20px;
  }
  .mt-30 {
    margin-top:30px;
  }
  .mt-15 {
    margin-top:15px;
  }
  
  .mb-0 {
    margin-bottom:0px !important
  }
  
  .mb-20 {
    margin-bottom:20px !important
  }
  
  .d-grid {
    display: grid !important;
  }
  .d-block {
    display: block !important;
  }
  .d-flex {
    display: flex !important;
  }
  .d-none {
    display: none !important;
  }
  .align-items-center {
    align-items: center !important;
  }
  .justify-content-center {
    justify-content: center !important;
  }
  .justify-content-between {
    justify-content: space-between !important;
  }
  .justify-content-around {
    justify-content: space-around !important;
  }
  .justify-content-start {
    justify-content: flex-start !important;
  }
  .flex-wrap {
    flex-wrap: wrap !important;
  }
  .flex-column {
    flex-direction: column !important;
  }
  
  .cursor-pointer {
    cursor: pointer !important;
  }
  
  .cursor-move {
    cursor: move !important;
  }
  .overflow-hidden {
    overflow: hidden !important;
  }
  .position-relative {
    position: relative !important;
  }
  .position-absolute {
    position: absolute !important;
  }
  .text-decoration-none {
    text-decoration: none !important;
  }
  
  @media (min-width:576px) {
    .mb-sm-0 {
      margin-bottom:0px !important
    }
    .mx-sm-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .d-sm-grid {
      display: grid !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-flex {
      display: flex !important;
    }
    .d-sm-none {
      display: none !important;
    }
    .align-items-sm-center {
      align-items: center !important;
    }
    .justify-content-sm-center {
      justify-content: center !important;
    }
    .justify-content-sm-between {
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      justify-content: space-around !important;
    }
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
    .flex-sm-wrap {
      flex-wrap: wrap !important;
    }
    .flex-sm-column {
      flex-direction: column !important;
    }
    .position-sm-relative {
      position: relative !important;
    }
    .position-sm-absolute {
      position: absolute !important;
    }
    .text-decoration-sm-none {
      text-decoration: none !important;
    }
  }
  
  @media (min-width:768px) {
    
    .my_custom_container_stepone{
      max-width: 1250px;
      margin: 0px auto;
      /* border-radius: 12px; */
      /* min-height: 550px; */
    }
  
    .mb-md-20 {
      margin-bottom:20px !important
    }
  
    .album_container {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }
    .mx-md-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .mb-md-0 {
      margin-bottom:0px !important
    }
    .d-md-grid {
      display: grid !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-flex {
      display: flex !important;
    }
    .d-md-none {
      display: none !important;
    }
    .align-items-md-center {
      align-items: center !important;
    }
    .justify-content-md-center {
      justify-content: center !important;
    }
    .justify-content-md-between {
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      justify-content: space-around !important;
    }
    .justify-content-md-start {
      justify-content: flex-start !important;
    }
    .flex-md-wrap {
      flex-wrap: wrap !important;
    }
    .flex-md-column {
      flex-direction: column !important;
    }
    .position-md-relative {
      position: relative !important;
    }
    .position-md-absolute {
      position: absolute !important;
    }
    .text-decoration-md-none {
      text-decoration: none !important;
    }
  }
  
  @media (min-width:992px) {
    .my_custom_container{
      max-width: 1250px;
      margin: 0px auto;
      /* border-radius: 12px; */
      /* min-height: 550px; */
    }
  
    .mb-lg-0 {
      margin-bottom:0px !important
    }
  
    .mb-lg-20 {
      margin-bottom:20px !important
    }
  
    .my_custom_container_stepone{
      max-width: 1250px;
      margin: 0px auto;
      /* border-radius: 12px; */
      /* min-height: 550px; */
    }
  
    .mx-lg-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .d-lg-grid {
      display: grid !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-flex {
      display: flex !important;
    }
    .d-lg-none {
      display: none !important;
    }
    .align-items-lg-center {
      align-items: center !important;
    }
    .justify-content-lg-center {
      justify-content: center !important;
    }
    .justify-content-lg-between {
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      justify-content: space-around !important;
    }
    .justify-content-lg-start {
      justify-content: flex-start !important;
    }
    .flex-lg-wrap {
      flex-wrap: wrap !important;
    }
    .flex-lg-column {
      flex-direction: column !important;
    }
    .position-lg-relative {
      position: relative !important;
    }
    .position-lg-absolute {
      position: absolute !important;
    }
    .text-decoration-lg-none {
      text-decoration: none !important;
    }
  }
  
  @media (min-width:1200px) {
    .mx-xl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .d-xl-grid {
      display: grid !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-flex {
      display: flex !important;
    }
    .d-xl-none {
      display: none !important;
    }
    .align-items-xl-center {
      align-items: center !important;
    }
    .justify-content-xl-center {
      justify-content: center !important;
    }
    .justify-content-xl-between {
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      justify-content: space-around !important;
    }
    .justify-content-xl-start {
      justify-content: flex-start !important;
    }
    .flex-xl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xl-column {
      flex-direction: column !important;
    }
    .position-xl-relative {
      position: relative !important;
    }
    .position-xl-absolute {
      position: absolute !important;
    }
    .text-decoration-xl-none {
      text-decoration: none !important;
    }
  }
  
  @media (min-width:1400px) {
    .mx-xxl-auto {
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .d-xxl-grid {
      display: grid !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-flex {
      display: flex !important;
    }
    .d-xxl-none {
      display: none !important;
    }
    .align-items-xxl-center {
      align-items: center !important;
    }
    .justify-content-xxl-center {
      justify-content: center !important;
    }
    .justify-content-xxl-between {
      justify-content: space-between !important;
    }
    .justify-content-xxl-around {
      justify-content: space-around !important;
    }
    .justify-content-xxl-start {
      justify-content: flex-start !important;
    }
    .flex-xxl-wrap {
      flex-wrap: wrap !important;
    }
    .flex-xxl-column {
      flex-direction: column !important;
    }
    .position-xxl-relative {
      position: relative !important;
    }
    .position-xxl-absolute {
      position: absolute !important;
    }
    .text-decoration-xxl-none {
      text-decoration: none !important;
    }
  }
  
  
  
  .spacer-y1 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .spacer-y2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .spacer-x1 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .spacer-r1 {
    margin-right: 5px !important;
  }
  .spacer-r2 {
    margin-right: 8px !important;
  }
  /* COLORS */
  
  .text-blue {
    color: var(--blue) !important;
  }
  .text-light-blue {
    color: var(--light-blue) !important;
  }
  .text-dark-grey {
    color: var(--dark-grey) !important;
  }
  .text-light-grey {
    color: var(--light-grey) !important;
  }
  .text-warning {
    color: var(--warning) !important;
  }
  
  .bg-blue {
    background-color: var(--blue) !important;
  }
  .bg-light-blue {
    background-color: var(--light-blue) !important;
  }
  .bg-dark-grey {
    background-color: var(--dark-grey) !important;
  }
  .bg-light-grey {
    background-color: var(--light-grey) !important;
  }
  .bg-warning {
    background-color: var(--warning) !important;
  }
  
  
  /* FONT SIZES & WEIGHTS */
  
  .bold400 {
    font-weight: 400 !important;
  }
  .bold500 {
    font-weight: 500 !important;
  }
  .bold600 {
    font-weight: 600 !important;
  }
  .bold700 {
    font-weight: 700 !important;
  }
  
  
  .font12 {
    font-size: 12px !important;
  }
  .font14 {
    font-size: 14px !important;
  }
  .font15 {
    font-size: 15px !important;
  }
  .font16 {
    font-size: 16px !important;
  }
  .font18 {
    font-size: 18px !important;
  }
  .font19 {
    font-size: 19px !important;
  }
  .font22 {
    font-size: 22px !important;
  }
  .font23 {
    font-size: 23px !important;
  }
  .font24 {
    font-size: 24px !important;
  }
  
  /* TEX ALIGNMENT */
  
  .text-center {
    text-align: center !important;
  }
  .text-left {
    text-align: left !important;
  }
  .text-right {
    text-align: right !important;
  }
  
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--light-grey) !important;
    opacity: 1; /* Firefox */
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--light-grey) !important;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--light-grey) !important;
  }
  
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }

  .password-modal-header {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    text-align: left;
    margin-bottom: 12px;
  }
  .password-modal-subheader {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(21, 25, 32, 0.5);
    text-align: left;
    margin-bottom: 12px;
  }
  .password-modal-input {
    width: 280px;
    height: 48px;
    background: rgba(123, 151, 206, 0.06);
    border: 1px solid #0A8A95;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 12px;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .password-modal-button {
    padding: 6px 16px;
    width: 280px;
    height: 42px;
    background: #0A8A95;
    border-radius: 8px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: white;
  }

  .simple-nav-links {
    padding: 0px 24px;
  }
  .simple-nav-link {
    margin: 0px 12px;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 20px;
  }
  .secondary {
    width: 167px;
    height: 58px;
    background: #ECF1F3;
    border-radius: 4px;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    color: #1E5C7F;
  }

  .primary {
    width: 217px;
    height: 58px;
    background: #11ADBA;
    border-radius: 4px;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: 20px;
    color: #FFFFFF;
  }

  .light-blue-background {
    background: rgb(245 247 252);
    padding-top: 24px;
  }