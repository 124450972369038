
.portrait, .landscape{
    margin: 10px;
}
.portrait{
    max-height: 40vh;
    max-width: 100%;
}
.landscape{
    width: 100%;
}
.square{
    max-height: 31vh;
    max-width: 75%;
}
.compare-container{
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    grid-row-gap: 15px;
}
.compare-image{
    margin: auto;
    cursor: pointer;
}

@media only screen and (min-width: 500px){
    .compare-container{
        grid-template-columns: 1fr 1fr;
    }
    .portrait{
        max-height: 80vh;
        max-width: 90%;
    }
    .square{
        max-width: 90%;
    }
    .left{
        margin: inherit;
        margin-left: auto;
        margin-right: 10px;
    }
    .right{
        margin: inherit;
        margin-right: auto;
        margin-left: 10px;
    }
    .square{
        max-height: 80vh;
    }
}



@media only screen and (min-width: 700px){
    .portrait, .square{
        max-height: 75vh;
        max-width: 90%;
    }
}