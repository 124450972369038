
.vendor-dashboard {
    background: #F3F7F8;
    display: grid;
    min-height: 100vh;
    font-family: 'Poppins';
    grid-template-columns: 300px 1fr;
}

.vendor-navbar {
    background: white;
    width: 300px;
    min-height: 100vh;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
}

.vendor-nav-item {
    font-size: 16px;
    width: 240px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #A9B7BB;
    display: flex;
    column-gap: 16px;
    height: 52px;
    align-items: center;
    padding: 16px 8px;
    cursor: pointer;
    border-radius: 8px;
}

.vendor-nav-item.selected {
    background: #EBF3F4;
    color: #0A8A95;
    font-weight: 600;

}

.vendor-navbar-mobile {
    display: none;
}

.mobile-only {
    display: none;
}

nav.vendor-navbar-mobile {
    margin-top: 16px;
    position: absolute;
    background: white;
    width: 100%;
    left: 0;
    top: 40px;
    padding-left: 12px;
    z-index: 2;
}

@media screen and (max-width: 860px) {
    .vendor-navbar-mobile {
        display: inherit;
    }
    .vendor-navbar-desktop {
        display: none;
    }
    .mobile-only {
        display: inherit;
    }
    .vendor-dashboard {
        display: block;
    }
    
    .vendor-dashboard-card {
        background: white;
        padding: 12px 8px;    
        max-width: 528px;
        border-radius: 16px;
        margin-top: 32px;
    }    
    .vendor-dashboard-body {
        padding: 8px;
    }
}

.vendor-dashboard-card {
    background: white;
    padding: 12px 16px;    
    max-width: 528px;
    border-radius: 16px;
    margin-top: 32px;
}

.vendor-dashboard-card-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    
}

.vendor-dashboard-button {
    font-size: 12px;
    font-weight: 600;
    padding: 6px 16px 6px 16px;
    border-radius: 28px;
}

.vendor-dashboard-button:disabled {
    background: #DBE0E1;
    color: #959CA9;

}

.vendor-dashboard-button-primary {
    background: #0A8A95;
    color: white;
}

.vendor-dashboard-button-secondary {
    color: #0A8A95;
    border: 1px solid #0A8A95
}

.vendor-dashboard-button-save {
    padding: 10px 32px 10px 32px;
    font-size: 14px;
}

.vendor-dashboard-text-input-wrapper {
    display: flex;
    flex-direction: column;
}
.vendor-dashboard-text-input-wrapper>label {
    font-weight: 600;
    font-size: 12px;
    color: #49576D;

}

.vendor-dashboard-text-input-wrapper>input, .vendor-dashboard-text-input {
    background: #F4F8F9;
    border: 1px solid #DFE7E9;
    border-radius: 6px;
    gap: 8px;
    padding: 10px 16px 10px 16px;
    border-radius: 6px;
    gap: 8px;
    font-family: Poppins;
    font-weight: 400;
}

.vendor-dashboard-text-inputs {
    display: flex;
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
}

.vendor-dashboard-body {
    padding: 32px;
}


@media screen and (max-width: 860px) {
    .vendor-dashboard-body {
        padding: 8px;
    }
}


.vendor-referral-banner {
    background: #64A0A9;
    padding: 6px 0px;
    color: white;
    text-align: center;
}

.vendor-dashboard-pricing-input {    
    background: #F4F8F9;
    border: 1px solid #DFE7E9;
    width: 73px;
    padding: 3px 6px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 400;    
}

.vendor-dashboard-pricing-checkbox {
    margin-right: 6px;
}

.vendor-dashboard-pricing-input::-webkit-outer-spin-button,
.vendor-dashboard-pricing-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.vendor-dashboard-pricing-input[type=number] {
  -moz-appearance: textfield;
}

.pricing-section.pricing-section-disabled {
    opacity: .5;
    /* text-decoration: line-through; */
}

.vendor-photos-wrapper {
    display: flex;
    gap: 11px;
    flex-wrap: wrap;
}

.vendor-photo-wrapper {
    position: relative;
    width: 160px;
    height: 160px;
}
.vendor-photo-wrapper>div>img {
    width: 100%;
    height: 100%;   
    border-radius: 8px;    
    object-fit: cover;
}

.vendor-photo-overlay {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: #00000038;
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;
}

.vendor-photo-overlay {
    display: none;
  }
  
  .vendor-photo-wrapper:hover .vendor-photo-overlay {
    display: flex;
  }

  .vendor-photo-section-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.vendor-photo-section-card-header>.vendor-dashboard-card-header {
    margin-bottom: 0px;
}

.vendor-dashboard-photo-rules {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: right;
}

.line-under {
    border-bottom: 1px solid #dddcdc;
    padding-bottom: 10px;
}

.vendor-dashboard-no-photos {
    text-align: center;
    width: 100%;
    padding: 16px 0px;
}

.vendor-dashboard-select {
    background: #F4F8F9;
    border: 1px solid rgba(196, 196, 196, 0.47);
    padding: 4px 8px;
    color: rgba(27, 45, 73, 0.8);
    border-radius: 8px;
    height: 46px;
}