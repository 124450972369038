/* .react-calendar {
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}


.react-calendar__navigation button {
  color: #768192;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}


.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e2e8f0;
}


.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: #4a5568;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5rem;
}

.react-calendar__month-view__days__day {
  text-align: center;
  padding: 0.75rem;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #bee3f8;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: #4299e1;
  border-radius: 6px;
  color: white;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #3182ce;
}

.react-calendar__tile--active {
  background: #2b6cb0;
  border-radius: 6px;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2c5282;
}

.react-calendar__tile--disabled {
  background: #f7fafc;
  color: #a0aec0;
}

.react-calendar__month-view__days__day--weekend {
  color: #e53e3e;
} */



/* Change the background color of the selected date */

/* Ensure that on hover, the background color doesn't revert */

.calendar-wrapper {
  transform: scale(0.85);
  transform-origin: top center;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -90px; /* Adjust this value to reduce space above calendar */
}

.react-calendar {
  padding: 10px 5px; /* Reduce side padding (second value) */
  width: 500px; /* Adjust if needed */
  font-size: 1em; /* Slightly reduce font size */
  line-height: 0px; /* Adjust line height for compactness */
}

/* Reduce padding in calendar tiles */
.react-calendar__month-view__days__day {
  padding: 0.3em 0;
}

.react-calendar__tile {
  font-size: 1.1em;
  padding: 0.5em 0.25em;
}
/* Adjust header sizes */
.react-calendar__navigation button {
  font-size: 1.2em;
}

/* Reduce space between calendar and checkbox */
.calendar-modal-content {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust this value to control spacing between elements */
}

.react-calendar__tile--active {
  background-color: #09737A !important;
  border: 2px solid #09737A !important;  /* Add a border to highlight the active tile */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important; /* Optional shadow for emphasis */
}