.booking-row{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
}
.filter-buttons-wrapper {
    display: flex;
}
.booking-row>td{
    border: 1px solid rgb(202, 202, 202);
    padding: 2px;
}