* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}
.subheader{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #768192;
    margin-bottom: 50px;
    }

.header{
font-family: 'Airbnb Cereal App Medium';
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 140%;
color: #49576D;
margin-bottom: 10px;
}
.question{
font-family: 'Airbnb Cereal App Medium';
font-style: normal;
font-weight: normal;
font-size: 28px;
line-height: 132%;
color: #1B2D49;
margin-bottom: 15px;
}


@media only screen and (max-width: 800px){
    .question{
        font-size: 20px;
    }
    .header{
        font-size: 16px;
    }
    .subheader{
        font-size: 14px;
    }
}
.question-container{
width: 80%;
max-width: 1000px;
margin: auto;
margin-top: 30px;
background: #FFFFFF;
border-radius: 12px;
padding: 70px 90px 70px 90px;
min-height: 550px;
}


@media only screen and (max-width: 800px){
    .question-container{
        width: inherit;
        padding: 30px 15px;
        margin: 15px;
    }
}

.question-page{
    background: #F5F8FA;
    min-height: 100vh;
    padding-bottom: 100px;
}

.dropdown-placeholder, .dropdown-chosen{
    font-family: 'Airbnb Cereal Light';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
}
.dropdown-placeholder{
    color: #A4ABB6;
}
.dropdown-chosen{
    color: #1B2D49;
    font-family: 'Airbnb Cereal Book';
    font-weight: bold;
}

.location-dropdown
{
    background: #FFFFFF;
    border: .5px solid #d1d5db63;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 20px;
    padding-left: 20px;
    box-shadow: 0px 3px 10px rgba(27, 45, 73, 0.05);
    max-width: 600px;
    cursor: default;
    position: relative;
    z-index: 5;
}

.question-button{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 25px;
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-sizing: border-box;
    border-radius: 6px;
    width: 144px;
    height: 50px;
    padding: 6px 12px;
    margin: 6px;
    border-radius: 12px;
}
.question-button.selected{
    background: #64A0A9;
    border: 1px solid #3D8893;
    color: #FEFEFE;
}

.results-button:hover{
    background: #64A0A9;
    border: 1px solid #3D8893;
    color: #FEFEFE;
}

.checkbox-label{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #49576D;
    margin-left: 16px;
}
button:focus{
    outline: none;
}
.question-tag-button{
    width: inherit;
}


@media only screen and (max-width: 800px){
    .question-tag-button{
        font-size: 12px;
    }
}

input.question-input {
    margin-right: 20px;
    height: 50px;
    padding-left: 20px;
    border-radius: 5px;
    border: 1px solid #D1D5DB;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 160%;
    color: #49576D;
    margin-bottom: 20px;
}

.email-name-input{
    width: 200px;
}

@media only screen and (max-width: 800px){
    .email-question-input{
        width: 100%;
    }
}

.navbar-new{
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    height: 80px;
}
.navbar-logo{
    width: 135px;
    margin-left: 50px;
    margin-top: 28px;
}
.navbar-feedback-button{
    background: #FFFFFF;
    border: 1px solid #3D8893;
    box-sizing: border-box;
    border-radius: 6px;
    height: 40px;
    width: 113px;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 21px;
    color: #3D8893;
    margin-top: 20px;
    margin-right: 50px;
}
.navbar-center, .navbar-right-mobile{
    display: none;
}

@media only screen and (max-width: 800px){
    .navbar-right, .navbar-left{
        display: none;
    }
    .navbar-center, .navbar-right-mobile{
        display: block
    }
    .navbar-new{
        height: 49px;
    }
}

.navbar-center{
    padding-top: 15px;
    text-align: center;
    width: 100%;
}
.navbar-right-mobile{
    position: absolute;
    right: 15px;
    top: 15px;
}
.steps, .steps-mobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.timeline-item{
    display: flex;
    margin: 25px;
    margin-bottom: 15px;
}

.timeline-text{
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 160%;
    color: #49576D;
}
.timeline-active{
    font-weight: bold;
}
.steps-mobile{
    display: none;
}
@media only screen and (max-width: 800px){
    .timeline-active{
        display: inline;
    }
    .steps-mobile{
        display: flex;
    }
    .steps{
        display: none;
    }
    .timeline-item{
        margin: 10px 0px;
    }
}
.steps-mobile{
    justify-content: space-around;
    background-color: white;
    box-shadow: 0px 1px 4px rgba(108, 126, 147, 0.1);
}

.mobile-timeline-text-wrapper{
    line-height: .8;
}
.timeline-logo{
    width: 20px;
    margin-right: 10px;
    margin-bottom: 2px;
}

.timeline-step-text{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #50949E;
}

.timeline-text-mobile{
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
}
.timeline-border{
    border-right: 1px solid #F0F0F4;
    padding-right: 10px;
    padding-left: 10px;
}
.timeline-logo-mobile{
    width: 32px;
}
.timeline-bar-wrapper{
    text-align: center;
    width: 80%;
    max-width: 1000px;
    margin: auto;
}
.timeline-bar{
    width: 100%;
}

@media only screen and (max-width: 800px){
    .timeline-bar-wrapper{
        display: none;
    }
    .timeline-item{
        display: flex;
    }
}

.footer-new {
    background-color: white;
    height: 80px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0px;
    width: 100%;
    transform: translateX(0);
}

.footer-back{
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 160%;
    display: flex;
    align-items: center;
    margin-left: 50px;
}

.footer-right{
    display: flex;
    align-items: center;
    margin-right: 50px;
}

.footer-next-button{
    font-family: 'Airbnb Cereal App Book';
    background: #3D8893;
    border-radius: 12px;
    border: 1px solid #3D8893;
    color: #FEFEFE;
    border-radius: 6px;
    height: 20px;
    margin: 6px;
    margin-left: 50px;
    width: 157px;
}
.footer-next-button.inactive{
    background: #D1D5DB;
    border-radius: 6px;
    border: none;
    cursor: default;
}


@media only screen and (max-width: 800px){
    .footer-right{
        margin-right: 20px;
    }
    .footer-back{
        margin-left: 20px;
    }
    .footer-next-button{
        height: 48px;
        width: max-content;
        padding: 0 16px; /* Adjust the padding as needed */
        white-space: nowrap; /* Prevents the text from wrapping */
    }
}
.footer-current{
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 160%;
}

@media only screen and (max-width: 800px){
    .footer-current{
        display: none;
    }
}
.date-select{
	background-color: white;
	padding: 12px;
	color: #32425B;
	font-size: 1.1rem;
	border: 1px solid #D1D5DB;
	border-radius: 6px;
    width: 140px;
    margin-right: 20px;
}

@media only screen and (max-width: 800px){
    .date-select{
        font-size: 14px;
        margin-right: 5px;
        width: inherit;
    }
}

.WithStyles\(ForwardRef\(Slider\)\)-mark-73 {
    width: 2px;
    height: 18px;
    transform: translateY(-20%);
    background-color: white;
}

.Mui-disabled>.MuiSlider-track {
    display: none;
}

.MuiSlider-thumb::after{
    top: 9px !important;
    left: 9.25px !important;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: #0a8a95;
    width: 14px !important;
    height: 14px !important;
}
.MuiSlider-thumb::before{
    top: 6px !important;
    left: 6px !important;
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: white;
    width: 6px !important;
    height: 6px !important;
    z-index: 20;
}
.MuiSlider-thumb {
    margin-top: -10px !important;
}
.MuiSlider-track {
    border-radius: 20px !important;
}

.slider-label{
    font-family: 'Airbnb Cereal App Book';
    font-size: 14px;
    line-height: 60%;
    color: #49576D;
    margin: 0px 10px;
}

.slider-wrapper{
    display: flex;
    flex-direction: row;
}
.mobile-slider-labels{
    display: none;
}
.slider-title{
	color: #32425B;
	margin-bottom: 12px;
	margin-top: -5px;
	padding-left: 5px;
	font-size: 18px;
	line-height: 100%;
	font-family: 'Airbnb Cereal App Medium';
}
@media only screen and (max-width: 800px){
    .slider-label{
        display: none;
    }
    .mobile-slider-labels{
        display: flex;
        justify-content: space-between;
        font-family: 'Airbnb Cereal App Book';
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 160%;
        color: #768192;
    }
    .slider-wrapper{
        flex-direction: column;
    }
    .slider-title{
        font-family: 'Airbnb Cereal App Medium';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        display: flex;
        color: #1B2D49;
    }
}
.how-important-checkbox-container {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    height: 20px;
    width: 20px;
    margin-left: 10px;
    transform: translateY(-2px);
    margin-right: 5px;
    margin-top: -13px;
}

@media only screen and (max-width: 800px){
    .how-important-checkbox-container {
        margin-left: 3px;
    }
}



/* Hide the browser's default checkbox */
.how-important-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.how-important-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;
    background-color: #617DC612;
    border: 1px solid black;
    border-radius: 4px;
    transform: translate(-10%, 10%);
}

.how-important-checkmark {
    border: 1px solid #CCCCCC;
}

.how-important-checkmark {
    background-color: white;
}

/* When the checkbox is checked, add a blue background */


/* Create the checkmark/indicator (hidden when not checked) */
.how-important-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.how-important-checkbox-container input:checked ~ .how-important-checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.how-important-checkbox-container .how-important-checkmark:after {
    left: 6px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.how-important-checkbox-container .how-important-checkmark:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.how-important-checkbox-container input:checked ~ .how-important-checkmark {
  background-color: #0a8a95;
;
}

.great-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #2228318a;
    z-index: 20;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.great-popup{
    position: absolute;
    width: 500px;
    height: 592px;
    background: #FFFFFF;
    border-radius: 12px;
    text-align: center;
    margin-bottom: 100px;
    padding: 0px 30px;
    max-width: 90%;
}

.loading{
    top: 200px;
    position: absolute;
    width: 500px;
    height: 592px;
    color: white;
    font-size: 60px;
    text-align: center;
}

.camera-image{
    margin-top: 40px;
}
.your-set{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #49576D;
    margin-top: 10px;
}
.star-average{
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: bold;
    font-size: 56px;
    line-height: 73px;
    color: #3D8893;
    margin-top: 10px;
}

.out-of{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #49576D;
}

.reviews{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 132%;
    color: #3D8893;
}

.lets-start{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #49576D;
    margin-top: 20px;
}

.got-it-button{
    width: 286.79px;
    height: 40px;
    background: #3D8893;
    border-radius: 6px;
    border: none;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 20px;
    box-shadow: 1px 4px 8px #3d889340;
}


@media only screen and (max-width: 800px){
    .camera-image{
        width: 90px;
    }
    .your-set{
        font-size: 14px;
    }
    .star-average{
        font-size: 48px;
        font-weight: normal;
    }
    .out-of{
        font-size: 14px;
    }
    .reviews{
        font-size: 20px;
    }
    .lets-start{
        font-size: 14px;
    }

}
.checkbox-container{
    margin-bottom: -3px;
    margin-top: 25px;
    margin-right: 16px;
    margin-right: 16px;
}


.Toastify__toast-container--bottom-center {
    bottom: 25%;
    width: 450px;
    max-width: 90vw;
  }

@media only screen and (max-width: 800px){
    .Toastify__toast-container--bottom-center {
        left: 5%;
    }
}
  .Toastify__toast--default {
    background-color: #f4f6fb;
    opacity: .9;

  }

  .Toastify{
    width: 450px;
    max-width: 90vw;
  }
  .toast-top{
    padding-left: 10px;
    display: grid;
      grid-template-columns: 1fr 1fr;
  }
  .toast-top-right{
    text-align: right;
  }
  .toast-body{
    font-size: 1.2rem;
  }
  .Toastify__progress-bar{
    background:#0a8a95;
  }
  .Toastify__toast-body {
    width: 100%;
  }
  button.Toastify__close-button.Toastify__close-button--default {
    display: none;
  }

  .location-option{
      padding: 5px 0px;
      width: 100%;
  }

  .privacy-wrapper {
    margin-top: 20px;
    margin-left: 5px;
    color: #768192;
    font-size: 12px;
}
.location-option:hover {
    font-family: 'Airbnb Cereal Book';
    font-weight: bold;
}


@media only screen and (max-width: 800px){
    .hide-on-mobile{
        display: none;
    }
}


@media only screen and (max-width: 800px){
    .results-header{
        font-family: 'Airbnb Cereal App Book';
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 160%;
        color: #49576D;
    }
}
.scroll-down-arrow{
    display: none;
}

@media only screen and (max-width: 800px){
    .scroll-down-arrow{
        display: block;
        position: fixed;
        bottom: 75px;
        right: 20px;
    }
}

.date-chosen{
    font-family: 'Airbnb Cereal App';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #32425B;
}
.date-dropdown{
    width: 140px;
    height: 50px;
    padding-top: 15px;
}

.date-dropdowns{
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
}

.date-chosen{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: #32425B;
}

.date-options{
    background-color: white;
    width: 140px;
    padding: 20px;
    margin-left: -20px;
    border: .5px solid #d1d5db63;
    box-sizing: border-box;
    border-radius: 12px;
    position: absolute;
    top: 0px;
    padding-top: 0px;
    z-index: 200;
}

@media only screen and (max-width: 800px){
    .date-dropdown{
        width: inherit;
        min-width: 110px;
    }
    .photo-progress-desktop{
        display: none;
    }
}

@media only screen and (min-width: 800px){
    .photo-progress-mobile{
        display: none;
    }
}
.photo-progress {
    position: relative;
    width: 100%;
    max-width: 700px;
    margin: auto;
    height: 4px;
    margin-top: 10px;
}
.photo-progress-mobile{
    margin-top: 0px;
}
.photo-progress-complete{
    background: #5DBBC9;
    border-radius: 4px;
    height: 4px;
    position: absolute;
}
.photo-progress-mobile>.photo-progress-complete{
    border-radius: 0px 3px 3px 0px;
}

.photo-progress-background{
    background: #E8EAED;
    border-radius: 4px;
    width: 100%;
    height: 4px;
    position: absolute;
}

.instructions-popup{
    height: 430px;
    width: 459px;
    padding: 40px 30px;
}
.instructions-top{
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: center;
    color: #3D8893;
    margin-bottom: 25px;
}

.instructions-numbers{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    text-align: left;
    color: #1B2D49;
    padding: 16px;
    display: grid;
    grid-template-columns: 20px 1fr;
    row-gap: 15px;
    margin-bottom: 25px;
}

.instructions-number{
    font-family: 'Airbnb Cereal App Medium';
}
.compare-button{
    width: 227px;
    height: 40px;
    background: #3D8893;
    border-radius: 6px;
    color: #FFFFFF;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    border: none;
    box-shadow: 1px 4px 8px #3d889340;
}


@media only screen and (max-width: 800px){

    .instructions-popup{
        height: 352px;
        width: 352px;
        padding: 30px 15px;
    }
    .instructions-top{
        font-size: 13px;
        color: #3D8893;
        margin-bottom: 15px;
    }
    .instructions-numbers{
        font-size: 13px;
    }
}

.share-button{
    background: #3D8893;
    border: 1px solid #3D8893;
    box-sizing: border-box;
    border-radius: 6px;
    font-family: 'Airbnb Cereal App Book';
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #FEFEFE;
    width: 108px;
    height: 40px;
}

.share-popup{
    width: 452px;
    height: auto;
    background: #F5F9FA;
    border-radius: 12px;
    padding: 60px;
    padding-bottom: 50px;
}


@media only screen and (max-width: 800px){
    .share-popup{
        padding: 50px 20px 30px 20px;
    }

}
.share-close-button {
    position: absolute;
    right: 22px;
    top: 20px;
    cursor: pointer;
}
.share-links {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
}

.share-main-text{
    font-family: 'Airbnb Cereal App Medium';
    font-size: 20px;
    line-height: 140%;
    color: #32425B;
    text-align: left;
}
.share-sub-text{
    font-family: 'Airbnb Cereal App Book';
    font-size: 12px;
    line-height: 160%;
    color: #1B2D49;
    margin-top: 3px;
}
.share-input-label{
    font-family: 'Airbnb Cereal App Book';
    font-size: 14px;
    line-height: 160%;
    color: #1B2D49;
    margin-bottom: 12px;
}

.share-submit-button, .share-cancel-button{
    background: #3D8893;
    color: #FEFEFE;
    border: 1px solid #3D8893;
    box-sizing: border-box;
    border-radius: 6px;
    width: 149px;
    height: 36px;
    font-family: 'Airbnb Cereal App Medium';
    font-size: 14px;
    line-height: 160%;
}

.share-submit-inactive{
    background: #D1D5DB;
    border: none;

}
.share-cancel-button{
    background: #F5F9FA;
    border: none;
    color: #3D8893;
}
.share-submit-wrapper{
    text-align: left;
}

@media only screen and (max-width: 800px){
    .share-main-text{
        font-size: 18px;
        line-height: 140%;
        text-align: center;
    }
    .share-submit-button, .share-cancel-button{
        width: 140px;
        height: 40px;
    }
}

@media only screen and (max-width: 350px){

    .share-submit-button, .share-cancel-button{
        width: 120px;
        height: 40px;
    }
}

.email-privacy-text{
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 160%;
    color: #32425B;
    margin-left: 6px;
    padding-top: 5px;
}

.email-privacy{
    background: #F4F4F5;
    border-radius: 5px;
    padding: 10px;
    padding-left: 22px;
    display: grid;
    grid-template-columns: 24px 1fr;
    margin-top: 40px;
    min-height: 50px;
    max-width: 519px;
}

@media only screen and (min-width: 800px){
    .email-privacy{
        align-items: center;
    }
}

.couples-love {
    background: #F7FAFB;
    border-radius: 12px;
    width: 249px;
    height: 198px;
    text-align: center;
    padding: 20px;
}

.email-page-container{
    display: grid;
    grid-template-columns: 1fr 280px;
}

.couples-love-header{
    font-family: 'Airbnb Cereal App Medium';
    font-size: 12px;
    line-height: 160%;
    color: #49576D;
    margin-bottom: 20px;
    margin-top: 10px;
}
.couples-body{
    font-family: 'Airbnb Cereal App Book';
    font-size: 12px;
    color: #768192;
    margin-top: 16px;
}
.couples-love-mobile{
    display: none;
    width: 100%;
    margin-bottom: 30px;
    margin-top: -10px;
}
.couples-love-desktop{
    margin-left: auto;
}


@media only screen and (max-width: 1000px){
    .couples-love-desktop{
        display: none
    }
    .couples-love-mobile{
        display: block;
    }
    .email-page-container{
        display: block;
    }
}

.photo-style-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 41px;
}


@media only screen and (max-width: 1000px){
    .photo-style-container{
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 5px;
    }
}

.photo-rank {
    position: absolute;
    top: calc(50% - 36px);
    left: calc(50% - 36px);
    width: 72px;
    height: 72px;
    border-radius: 50%;
    background: #ECF3F4E5;
    ;
    align-items: center;
    display: flex;
    justify-content: center;
    font-family: 'Airbnb Cereal App Medium';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 132%;
    color: #000000;
}

.copy-confirm{
    font-family: 'Airbnb Cereal App Book';
    position: absolute;
    padding: 10px;
    box-shadow: 0px 3px 10px rgba(27, 45, 73, 0.15);
    background-color: white;
    left: -40px;
    width: 120px;
    border-radius: 6px;
}
.results-confirm{
    left: 265px;
    bottom: -78px;
}
.search-icon {
    position: absolute;
    left: 550px;
    top: 20px;
}

@media only screen and (max-width: 760px) {
    .results-confirm{
        left: calc(50% - 60px);
    }
    .search-icon {
        left: calc(100% - 50px);
    }
}
@media only screen and (max-width: 760px) {
    .share-links-desktop{
        display: none;
    }
}
@media only screen and (min-width: 760px) {
    .share-links-mobile{
        display: none;
    }
}


@media only screen and (max-width: 960px) {
    .search-icon {
        left: calc(100% - 50px);
    }
}

@media only screen and (max-width: 800px) {
    .search-icon {
        left: 550px;
    }
}

@media only screen and (max-width: 650px) {
    .search-icon {
        left: calc(100% - 50px);
    }
}


input.searchbox-input {
    border: .5px solid #d1d5db63;
    border-radius: 12px;
    box-shadow: 0px 3px 10px rgb(27 45 73 / 5%);
    font-family: 'Airbnb Cereal Book';
    font-weight: bold;
    height: 67px;
    width: 604px;
    max-width: 100%;
}
input.searchbox-input::placeholder {
    color: #A4ABB6 !important;
    font-family: 'Airbnb Cereal Light';
    font-weight: normal;
}

.want-to-book {
    font-family: 'Public Sans', sans-serif;
    font-size: 18px;
    margin-top: 36px;
    max-width: 80%;
    text-align: center;
}

.request-header {
    font-family: 'Public Sans', sans-serif;
    font-weight: bold;
    font-size: 19px;
}

.request-subheader {
    color: #0A8A95;
    font-family: 'Public Sans', sans-serif;
    margin: 12px 0px;
    font-size: 12px;
}

.request-body {
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: 500;
}

.request-disclaimer {
    font-family: 'Public Sans', sans-serif;
    color: rgba(21, 25, 32, 0.32);
    margin-bottom: 12px;
    font-size: 12px;
}

.request-more-information {
    font-family: 'Public Sans', sans-serif;
    font-size: 16px;
    color: rgba(21, 25, 32, 0.5);
    margin-bottom: 16px;
}

.want-to-book-wrapper {
    margin-right: auto;
    justify-content: center;
    display: flex;
}

.modal-background {
    width: 100vw;
    height: 100%;
    position: fixed;
    background: #53535397;
    top: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-window {
    background: #F5F9FA;
    position: fixed;
    max-width:100%;
}

.calendar-selected.react-calendar__tile--active {
    background-color: #3D8893 !important;
    background: #3D8893 !important;
    color: white !important;
}

.new-quiz-question {
    border: 1px solid #D1D5DB;
    padding: 2px 9px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    min-width: 150px;
    height: 30px;
    line-height: 1;
}
input.new-quiz-question {
    cursor: text;
}

.new-quiz-question.small {
    min-width: 75px;
    width: 75px;
}
.new-quiz-question.answered {
    background: #3D8893;
    color: white;
}

.new-quiz-done-button {
    width: 231px;
    height: 40px;
    border-radius: 6px;
    background: #64A0A9;
    color: white;
}

.new-quiz-done-button:disabled {
    background: #BBC5C6;
}

.new-quiz-unavailable {
    opacity: .5;
}

.new-quiz-modal {
    position: fixed;
    max-width: 100vw;
    width: 600px;
    padding: 16px;
    top: 0;
    background: white;
    border-radius: 8px;
    max-height: 800px;
    z-index: 3;
}

dialog {
    background: white;
    margin: 0 auto;
    top: 16px;
    padding: 16px;
    border-radius: 16px;
}