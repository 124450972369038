.stepper_container {
    /* padding: 0 20px; */
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F5F9FA;
    border: 1px solid rgba(27, 45, 73, 0.5);
}
.stepper_item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding:10px 50px;
    /* border-top-right-radius: 50%;
    border-bottom-right-radius: 50%; */
    /* clip-path: polygon(0px 0px, 10px 0px, 100px 50px, 10px 100px, 0px 100px); */
    /* background-color:blue;  */
    /* flex:1 */
}

.mobile_stepper {
    background: #F5F9FA;
    height: 114px; 
    flex: 1; 
    display: flex;
    /* margin-top:20px; */
    border: 1px solid rgba(196, 196, 196, 0.47);
    box-sizing: border-box;
    padding: 20px;
}

.circle_40 {
    display: flex;
    justify-content: center;
    background-color: var(--blue);
    color: white;
    font-weight: 600;
    font-size: 20px;
    align-items: center; 
    border-radius: 50%;
    height: 40px;
    width: 40px;
}

.number_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; 
    border-radius: 50%;
    height: 30px;
    width: 30px;
}

.next_btn {
    background: #BBC5C6;
    padding:10px 15px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
}

.next_btn.create-active {
    background: var(--blue);
    cursor: pointer;
}

.keyword_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.keyword_box {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 13px 15px;
    margin-top: 10px;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    color: var(--dark-grey);
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.47);
}
.keyword_box.create-active {
    color: #FFFFFF;
    background: var(--blue);
}


.floating_inputs_wrapper{
    position: relative;
    /* margin-bottom: 20px; */
}
/* input[type="text" i] {
    padding: 0;
} */
.floating_inputs_wrapper>input{
    /* min-width: 315px !important; */
    width: 100%;
    /* max-width: 330px; */
    height: 50px;
    padding-left: 20px;
    border: 1px solid rgba(196, 196, 196, 0.47);
    color: var(--dark-grey);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 5px;
}

.date_input {
    /* min-width: 315px !important; */
    width: 100%;
    /* max-width: 330px; */
    cursor: pointer;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: 50px;
    padding-left: 20px;
    border: 1px solid rgba(196, 196, 196, 0.47);
    box-sizing: border-box;
    color: var(--dark-grey);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 5px;
}

.date_input:focus-visible {
    outline: none !important;
    border: 1px solid #1B2D49;
}
.calendar_icon_positioning {
    position:absolute; 
    right:50px;
    top: 50%;
    transform: translateY(-50%);
}
.react-datepicker-popper{
    z-index: 10 !important;
}
.floating_inputs_wrapper>input.error{
    border: 1px solid var(--warning) !important;
}
.floating_inputs_wrapper>input:focus-visible {
    outline: none !important;
    border: 1px solid #1B2D49;
}
.css-1okebmr-indicatorSeparator {
    display: none !important;
}
.floating_inputs_wrapper>select{
    /* min-width: 315px !important; */
    width: 100%;
    /* max-width: 330px; */
    height: 50px;
    padding-left: 20px;
    background: #F5F9FA;
    border: 1px solid rgba(196, 196, 196, 0.47);
    color: var(--dark-grey);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 5px;
}

.floating_inputs_wrapper>select.error{
    border: 1px solid var(--warning) !important;
}
.floating_inputs_wrapper>select:focus-visible {
    outline: none !important;
    border: 1px solid #1B2D49;
}

.white_background_select {
    background: #FFFFFF;
    padding: 30px 5% 20px 5%;
}


.transparent_background_padding {
    padding: 10px 5%;
}

/* @media (min-width: 1400px) {
    .floating_inputs_wrapper>input {
        max-width: 362px;
    }
    .floating_inputs_wrapper>select {
        max-width: 362px;
    }
} */

.signup-input-label{
    position: absolute;
    top: -15px;
    background: white;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #1B2D49;
    margin-left: 15px;
    padding: 5px;
}
.signup-input-label-special{
    position: absolute;
    top: -7px;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #1B2D49;
    margin-left: 15px;
    padding: 5px;
    background: rgb(245 247 252);
}

.signup-input-label-special.white {
    top: 13px;
    background: #FFFF !important;
}

.signup-input-label.create{
    background-color: #f5f9fa;
}


@media (min-width:768px) {
    .calendar_icon_positioning {
        right:20px;
    }

    .white_background_select {
        background: transparent !important;
        padding: 0px;
    }

    .signup-input-label-special.white {
        background: #f5f9fa !important;
    }
    .signup-input-label-special{
        position: absolute;
        top: -15px !important;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;
        color: #1B2D49;
        margin-left: 15px;
        padding: 5px;
    }
    .transparent_background_padding {
        padding: 0px !important;
    }
}

.signup-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.required-star{
    color: #0A8A95;
    margin-left: 4px;
}

.vendor_img_container {
    width: 60px;
    height: auto;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.47);
    box-sizing: border-box;
}
option {
    background: #FFFFFF !important;
    border: 1px solid rgba(196, 196, 196, 0.47) !important;
    box-sizing: border-box !important;
    box-shadow: 0px 14px 10px 2px rgba(0, 0, 0, 0.08) !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    padding-right: 10px;
}