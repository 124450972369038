
#banner-wrap {
    position:relative;
    max-width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
}
#banner, #banner2, #banner3, #banner4{
    overflow-x: hidden;
    overflow-y: hidden;
}
#banner::after {
    content: "";
    display:block;
    background: linear-gradient(90deg, #0A8A95 20%, rgba(249, 254, 254, 0) 78%);
    width:100vw;
    height:2px;
    position:absolute;
    left:-20vw;
    top:10vh;
    z-index:0;
    transform: rotate(-17deg);
    overflow-x: hidden;
    overflow-y: hidden;
}
#banner2::after {
    content: "";
    display: block;
    background: linear-gradient(
256deg, #0A8A95 42.19%, #F9FEFE 100%);
    width: 200vw;
    height: 2px;
    position: absolute;
    left: -5vw;
    top: 38vh;
    z-index: 0;
    transform: rotate(
-15deg);
overflow-x: hidden;
overflow-y: hidden;
}
#banner3::after {
    content: "";
    display: block;
    background: #F5F9FA;
    width: 200vw;
    height: 800px;
    position: absolute;
    left: -5vw;
    top: calc(38vh + 16px);
    z-index: 0;
    transform: rotate(
-15deg);
overflow-x: hidden;
overflow-y: hidden;
}
#banner4::after{
    content: "";
    display:block;
    background: linear-gradient(90deg, #0A8A95 30%, rgba(249, 254, 254, 0) 60%);
    width:200vw;
    height:2px;
    position:absolute;
    left:-20vw;
    top:80vh;
    z-index:1;
    transform: rotate(-15deg);
    overflow-x: hidden;
    overflow-y: hidden;
}

.signup-page {
    display: flex;
    justify-content: center;
    padding-top: 147px;
    column-gap: 130px;
}

.signup-left {
    max-width: 425px;
    margin-top: 175px;
    z-index: 2;
}

.signup-right {
    width: 720px;
    background-color: white;
    box-shadow: 0px 11px 12px rgba(0, 0, 0, 0.07);
    border: 1px solid #EDF4F4;
    padding: 60px 30px;
    z-index: 2;
}

.signup-right-create{
    background-color: #F5F9FA;
    box-shadow: none;
    margin-top: 125px;
    width: 610px;
}

.signup-right-header{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    color: #1B2D49;
    text-align: center;
    margin-bottom: 30px;
}

.for{
    font-size: 14px;
}

.signup-left-section-header{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #1B2D49;
    margin-top: 22px;
    margin-bottom: 10px;
}

.signup-left-section-body{
    font-family: 'Montserrat';
    font-size: 14px;
    line-height: 23px;
}

.signup-left-section{
    margin-top: 40px;
}

.photographer-signup-input-wrapper{
    position: relative;
    margin-bottom: 20px;
}

.photographer-signup-input-wrapper>input{
    width: 315px;
    height: 50px;
    padding-left: 20px;
    border: 1px solid rgba(196, 196, 196, 0.47);
    color: rgba(27, 45, 73, 0.8);
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 5px;
}

.photographer-signup-input-label{
    position: absolute;
    top: -15px;
    background-color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #1B2D49;
    margin-left: 15px;
    padding: 5px;
}

.photographer-signup-input-label.create{
    background-color: #F5F9FA;
}

.signup-inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-top: 1px solid rgba(196, 196, 196, 0.47);
    padding-top: 40px;
}

.required-star{
    color: #0A8A95;
    margin-left: 4px;
}

.divider{
    background: rgba(196, 196, 196, 0.47);
    height: 1px;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.how-hear{
    font-family: 'Montserrat';
    font-size: 16px;
    width: 100%;
    height: 60px;
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.47);
    padding: 0px 16px;
    color: rgba(27, 45, 73, 0.8);
}

.how-hear>option{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #1B2D49;
}

.signup-question{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    color: #1B2D49;
    margin-bottom: 24px;
    margin-top: 12px;
}

.signup-button{
    width: 100%;
    height: 50px;
    background: #0A8A95;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    margin-top: 48px;
    cursor: pointer;
}

.signup-button:disabled{
    background-color: #cbcbcb;
    border: none;
}

@media only screen and (max-width: 1400px) {
    .signup-left{
        display: none;
    }
    .signup-page{
        padding-top: 10px;
        background: #F5F9FA;
    }
    .signup-right, .photographer-signup-input-label, .how-hear, .photographer-signup-input-wrapper>input{
        background: #F5F9FA;
    }
    .signup-right-create{
        min-height: calc(100vh - 150px);
    }
}

@media only screen and (max-width: 1000px) {
    .photographer-signup-input-wrapper, .photographer-signup-input-wrapper>input{
        width: 100%;
    }
}

.signup-disclaimer{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: rgba(27, 45, 73, 0.8);
}

.have-an-account{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    color: #000000;
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

a.signup-link{
    color: #0A8A95;
    font-weight: 600;
}

.photographer-signup-input-wrapper.create>input{
    background-color: #F5F9FA;
}

.error{
    color: rgb(231, 0, 0);
    font-size: .8rem;
    margin-left: 20px;
}

.search-results {
    position: absolute;
    top: 49px;
    width: 100%;
    background: white;
    z-index: 10;
    padding: 5px 15px;
    border: 1px solid rgba(196, 196, 196, 0.47);
    box-sizing: border-box;
    box-shadow: 0px 14px 10px 2px rgb(0 0 0 / 8%);
    max-height: 800px;
    overflow-y: scroll;
}

.search-results>div {
    font-family: 'Montserrat';
    font-size: 15px;
    line-height: 18px;
    color: #1B2D49;
    border-bottom: 1px solid rgba(196, 196, 196, 0.47);
    margin-top: 10px;
    padding-bottom: 8px;
    cursor: pointer;
}

.overlay.transparent{
    z-index: 5;
    background:#00000000
}

.agree-button{
    height: 42px;
    background: #0A8A95;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 0px 10px;
    border-radius: 8px;
    width: 136px;
    margin: 0px 4px;
}

.agree-button.decline{
    border: 1px solid rgba(86, 103, 137, 0.26);
    background-color: white;
    color: rgba(21, 25, 32, 0.5);
    margin-bottom: 6px;
}


.signup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #00000093;
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signup-modal {
    background: white;
    text-align: center;
    padding: 26px;
    border-radius: 5px;
    max-width: 340px;
    box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.16);
}

.signup-modal-header {
    text-align: left;
    font-family: 'Airbnb Cereal App Book';
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 24px;
    margin-bottom: 18px;
}

.signup-modal-subheader {
    font-family: 'Airbnb Cereal App Medium';
    font-weight: 500;
    text-align: left;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 18px;
}

.signup-modal-faded {
    color: rgba(21, 25, 32, 0.5);
    font-family: 'Airbnb Cereal App Light';
    font-size: 14px;
    text-align: left;
    margin-bottom: 18px;
}

.choose-role-label{
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 600;
}

.role-text{
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
}

.role-button{
    background: #F5F9FA;
    width: 236px;
    height: 50px;
    border: 1px solid rgba(196, 196, 196, 0.47);
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    column-gap: 10px;
}
.role-button-active {
    border: 1px solid #0A8A95;
    color: #0A8A95;
}

.choose-role{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
}

.role-buttons-wrapper{
    display: flex;
}

.signup-subtext{
    font-family: 'Montserrat';
    font-size: 13.5px;
    margin-bottom: 12px;
}

.three-input-wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    column-gap: 15px;
}

@media only screen and (max-width: 1400px) {
    .role-button{
        width: 168px;
    }
    .choose-role{
        display: block;
    }
    .three-input-wrapper {
        grid-template-columns: 1fr;
    }
    .banners {
        display: none;
    }
}

@media only screen and (max-width: 550px) {
    .mobile-100 {
        margin-top: 20px;
        padding: 0px 20px;
        height: 90px;
        white-space: pre-wrap;
        overflow: hidden;
    }
}