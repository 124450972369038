.wedmatch_header {
    background-color: var(--light-blue);
    display: flex;
    flex-wrap: wrap;
    padding: 25px 0px;
    align-items: center;
    justify-content: space-between;
}

.support_logo_container {
    width: 54px;
    height: 54px;
    margin-right: 10px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}