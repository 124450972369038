main {
    padding: 0 0 2rem;
}

.flex {
    display: flex;
}

.flex__column {
    flex-direction: column;
}

.flex__align_center {
    align-items: center;
}

.flex__justify_center {
    justify-content: center;
}

.flex__justify_between {
    justify-content: space-between;
}


.nav {
    gap: 2rem;
    display: flex;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto 2rem;
}

.logo {
    margin-left: 1rem;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}


.vendor_dashboard {
    background: rgb(245 247 252);
}

.vendor_dashboard a {
    text-decoration: none;
    color: #000;
    transition: .1s;
}

.vendor_dashboard a:hover {
    background: #e4f1f2;
}

.vendor_dashboard__subheader {
    margin: 3rem 0 2rem;
    font-size: 1.375rem;
}

.vendor_dashboard__logo_form {
    gap: 1rem;
}

.vendor_dashboard__logo_form>img {
    object-fit: cover;
}

.vendor_dashboard__logo_form>button, .vendor_dashboard__logo_form>label {
    padding: .25rem 1rem;
    margin: 0;
    cursor: pointer;
    transition: .1s;
    border-width: 1px;
    border-style: solid;
    border-radius: 1.75rem;
    font-weight: 500;
}

.vendor_dashboard__logo_form>label {
    border-color: #0A8A95;
    background: #0A8A95;
    color: #fff;
}

.vendor_dashboard__logo_form>button {
    color: red;
    border-color: red;
}

.vendor_dashboard__logo_form>button:hover {
    background: red;
    color: #fff;
}

.vendor_dashboard__logo_form>label:hover {
    background: green;
}


.vendor_dashboard__form {
    max-width: 800px;
}

.submit_button {
    background: #11adba;
    color: #fff;
    font-weight: 600;
    border-radius: .375rem;
    padding: .5rem 1rem;
    margin-top: 1rem;
    transition: .1s;
}

.submit_button:hover {
    opacity: .8;
}

.submit_button[disabled] {
    background-color: #d3d3d3;
    cursor: not-allowed;
}

/* Notification */
.notification {
    position: fixed;
    bottom: 10px;
    right: 20px;
    padding: 1rem 2rem;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
}

.notification.error {
    background-color: red;
}

.notification.success {
    background-color: green;
}

.close_button {
    cursor: pointer;
    padding-left: 10px;
    position: absolute;
    top: 0;
    right: 5px;
}


@media (max-width: 768px) {
    .nav {
        gap: .5rem
    }

    .flex {
        flex-direction: column;
    }

    .vendor_dashboard__input {
        width: 100% !important;
        padding: 10px 0;
    }
}