.top_bar {
    padding: 30px 0px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.create_album_searchbar {
    min-width: 215px;
    height: 52px;
    background: #F5F9FA;
    padding-left: 52px;
    font-size: 18px;
    font-weight: 400;
    color: --var(--dark-grey);
    border: 1px solid rgba(196, 196, 196, 0.47);
}

.calendar_dropdown_btn {
    /* min-width: 215px; */
    background: #F5F9FA;
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
}

@media (min-width: 768px) {
    .calendar_dropdown_btn {
        border: 1px solid rgba(196, 196, 196, 0.47) !important;
    }
}

.create_album_card {
    width: 300px;
    min-height: 174px;
    display: flex;
    margin-right: 5px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #F8FEFF;
    border: 1px dashed #0A8A95;
}

.album_card {
    width: 300px;
    /* height: 341px; */
    position: relative;
    margin: 0 5px 10px 5px;
    background: #FFFFFF;
    border: 1px solid rgba(196, 196, 196, 0.47);
    box-sizing: border-box;
}

.card_dropdown_btn {
    position: absolute;
    top:10px;
    right:10px;
    height: 35px;
    width: 35px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(64, 73, 74, 0.13);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.card_dropdown_box {
    position: absolute;
    /* display: none; */
    top:0px;
    right:0px;
    /* padding: 20px 0; */
    width: 0px;
    width: 190px;
    /* height: 0px; */
    background: #FFFFFF;
    box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.36);
    transform: scaleY(0);
    overflow: hidden;
    transition: none;
}

.card_dropdown_box.open {
    top:40px;

    /* display: block; */
    /* width: 190px; */
    /* height: auto; */
    transition: all 0.2s;
    display: block;
    transform: scale(1);
}

.card_dropdown_item {
    display: flex;
    align-items: center;
    padding: 16px 18px;
}

.card_dropdown_item:hover {
    background: var(--light-blue);
}

.view_all_overlay {
    position:absolute; 
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
    text-decoration: underline;
    cursor: pointer;
    background:rgba(27, 45, 73, 0.52)
}

.empty_half_circle {
    position: relative;
    width: 100%;
    max-width: 720px;
    height: 360px; /* as the half of the width */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background-color: transparent;
    border-bottom-left-radius: 830px;  /* 100px of height + 10px of border */
    border-bottom-right-radius: 830px; /* 100px of height + 10px of border */
    border: 1px dashed rgba(196, 196, 196, 0.47);
    border-top: 0;
}

@media (min-width:1200px) {
    .empty_half_circle {
        max-width: 820px;
        height: 410px; /* as the half of the width */
    }
}

.empty_album_card {
    width: 330px;
    /* width: 100%; */
    height: 174px;
    display: flex;
    margin-right: 5px;
    margin-top: 30px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background: #F8FEFF;
    border: 1px dashed #0A8A95;
}

@media (min-width:768px) {
    .empty_album_card {
        width: 370px !important;
    }
}

.empty_image_container {
    width: 130px;
    position: absolute;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(177, 177, 177, 0.35);
}