.dashboard-container {
  background-color: #f4f6fb !important ;
  background: #f4f6fb !important  ;
  font-family: 'Georgia, serif' !important;
  -webkit-font-family: 'Georgia, serif' !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.dashboard-logo {
  width: 200px;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
}

.navlink-dashboard {
  text-decoration: none;
  font-size: 20px;
  color: #6a6868;
  transition: color 0.1s ease-out !important;
  font-family: NexaLight;
  text-transform: uppercase;
  cursor: pointer;
}

.active {
  text-decoration: none;
  transition: color 0.1s ease-out;
  font-family: NexaLight;
  text-transform: uppercase;
  color: #0a8a95 !important;
  font-size: 25px !important;
}

.navlink-dashboard:hover {
  color: #0a8a95 !important;
}

.navlink-dashboard.disabled {
  color: #6a686879 !important;
  cursor: default;
  text-decoration: none !important;
}
.dashboard-sub-container {
  margin-left: 280px;
  width: calc(100% - 280px);
  font-family: 'Georgia' !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  min-height: 100vh;
  padding-top: 60px;
  color: #6a6868;
  overflow-x: hidden;
  box-sizing: border-box;
  text-align: center;
}

.dashboard-text-1 {
  font-size: 20px;
  color: #6a6868;
}

.dashboard-text-2 {
  font-size: 22px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: #6a6868;
  text-transform: capitalize;
  font-weight: bold;
  letter-spacing: 0.2px;
  max-width: 90%;
  margin: 0 auto;
  text-align: center;
}

.auth-text {
  text-align: left;
  font-size: 20px;
  margin-bottom: 20px;
  color: #6a6868;
}

.dashboard-container input[type='file'] {
  display: none;
}

.custom-file-upload {
  font-family: 'Georgia' !important;
  border-radius: 10px;
  font-size: 16px;
  padding: 10px;
  background-color: #0a8a95;
  cursor: pointer;
  color: #fff;
  -webkit-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 8px -6px rgba(0, 0, 0, 0.2);
  width: 120px;
  text-align: center;
  border: none;
}

.seperator {
  height: 0.5px;
  background-color: #6a6868;
  width: 50%;
  margin: 10px 0;
}

.special-category {
  background-color: rgba(10, 138, 149, 0.2) !important;
  width: 100%;
  text-align: center;
}

.dashboard-info-input {
  padding: 12px 20px;
  font-size: 1.2rem;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;

  color: #6a6868;
  border: none;
  border-radius: 15px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

input[type='number'].dashboard-pricing-input {
  padding: 5px 10px;
  font-size: 0.8rem;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  color: #6a6868;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  width: 70px;
  font-family: inherit;
}

.dashboard-personality-input {
  padding: 10px;
  font-size: 0.8rem;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  text-align: center;
  color: #6a6868;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  width: 50px;
  height: 30px;
  margin-bottom: 10px;
  margin-right: 5px;
}

.authentication-input {
  padding: 5px 10px;
  font-size: 1.2rem;
  -moz-box-shadow: 0 0 3px #ccc;
  -webkit-box-shadow: 0 0 3px #ccc;
  box-shadow: 0 0 3px #ccc;
  text-align: 'center';
  color: #6a6868;
  border: none;
  border-radius: 8px;
  box-sizing: border-box;
  width: 250px;
  height: 40px;
  margin-bottom: 20px;
}

th,
td {
  font-weight: normal;
  font-size: 15px;
}

.month,
.day {
  padding: 10px;
  font-size: 16px;
  margin: 3px 5px;
  border: 1px solid #6a6868;
  color: #6a6868;
}

.month {
  width: 60px;
}

.day {
  width: 40px;
}
.month.selected,
.day.selected {
  background-color: #0a8a95;
  color: #fff;
}
.hide-nav {
  display: none !important;
}
.referral-banner {
  margin-left: 280px;
  height: 52px;
  background-color: #0a8a95;
  font-size: 18px;
  line-height: 23px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Airbnb Cereal App Book';
}

/* MEDIA QUERIES */

@media (max-width: 1000px) {
  .dashboard-sub-container {
    margin: 0;
    padding-top: 80px;
    width: 100%;
  }

  .referral-banner {
    margin: 0;
    padding-left: 60px;
    height: 100px;
    flex-wrap: wrap;
  }
}

@media only screen and (max-device-width: 500px) {
  .dashboard-sub-container {
    margin-left: 0;
    padding: 10px;
    padding-top: 60px;
  }

  .dashboard-pricing-input {
    width: 50px;
    height: 30px;
  }
  .month,
  .day {
    margin: 8px;
  }

  .dashboard-personality-input {
    width: 40px;
    height: 35px;
  }
}

.tip {
  position: absolute;
  padding: 10px;
  left: 30px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.295);
  background-color: white;
  border-radius: 5px;
  font-weight: normal;
  font-size: 0.8rem;
  text-transform: none;
  width: 450px;
  text-align: left;
}

@media (max-width: 1000px) {
  .tip {
    left: -10px;
    padding-top: 20px;
    width: 100%;
  }
}

.close-tooltip-button {
  position: absolute;
  right: 10px;
  top: 5px;
  text-align: right;
  color: gray;
  font-family: sans-serif;
  cursor: pointer;
  font-size: 1.2rem;
}

.tip-header {
  color: black;
  font-size: 1rem;
  text-align: center;
}

.question-mark {
  margin-left: 5px;
  width: 16px;
  padding-bottom: 5px;
  cursor: pointer;
}
.tag-button {
  /*display: none*/
}
.dashboard-photo-wrapper:hover > .tag-button,
.dashboard-photo-wrapper:hover > .times-of-day,
.dashboard-photo-wrapper:focus > .tag-button,
.dashboard-photo-wrapper:focus > .times-of-day,
.tap-detector:active > .times-of-day {
  display: inline-block;
}
.tag-button {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 5;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
}
.times-of-day {
  background-color: rgba(0, 0, 0, 0.441);
  /*display: none*/
  position: absolute;
  color: white;
  width: 180px;
  height: 180px;
  top: 10px;
  left: 10px;
  padding-top: 10px;
  font-size: 1.1rem;
}
.time-of-day-option {
  cursor: pointer;
}

.dashboard-photo-wrapper {
  background-color: white;
  box-shadow: 0px 15px 10px -15px #999;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 200px;
  
}
.dashboard-photo-wrapper .__react_component_tooltip {
  font-size: 1.2em;
  max-width: 12em;
}

.photo-type-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px 0;
}
.lightbox {
  width: 700px;
  z-index: 20;
  overflow-y: auto;
  margin: auto;
  max-width: 100%;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  text-align: center;
  width: 100vw;
  height: 100vh;
  background-color: #00000093;
  z-index: 200;
  padding-top: 10px;
}

.close-button {
  position: absolute;
  top: -15px;
  right: 30px;
  color: white;
  font-size: 4rem;
  font-family: monospace;
  cursor: pointer;
}
.inactive-tag,
.active-tag {
  border-radius: 5em;
  font-size: 0.9rem;
  padding: 6px;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 300px;
  -webkit-box-shadow: 0px 5px 15px -6px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0px 5px 15px -6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px -6px rgb(0 0 0 / 20%);
  cursor: pointer;
  font-family: inherit;
  margin-bottom: 10px;
}
.inactive-tag {
  color: #707070;
  border: 1px solid #707070;
  background-color: #ffffff;
}
.active-tag {
  color: white;
  border: 1px solid #707070;
  background-color: #0a8a95;
}
.next-photo {
  position: absolute;
  color: white;
  right: 50px;
  font-size: 4rem;
  top: 10%;
  cursor: pointer;
}
.matches-row {
  display: grid;
  grid-template-columns: 120px 140px 100px 120px 220px 200px;
  margin-bottom: 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.396);
}

.header-row {
  border-top: none;
}

.booked-cell {
  display: flex;
}
/*
.matches-row {
  display: flex;
  flex-wrap: wrap;
}
.date-cell {
  width: 120px;
}

.name-cell {
  width: 200px;
}
.email-cell {
  width: 220px;
  overflow-wrap: anywhere;
}
.venue-cell {
  width: 220px;
  overflow-wrap: anywhere;
}
.booked-cell {
  display: flex;
}
.header-row {
  font-weight: bold;
}
.matches-row {
  display: flex;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  padding-top: 10px;
}
.booked-cell {
  display: flex;
}
*/
.booked-button {
  border-radius: 0.4em;
  color: rgb(112, 112, 112);
  font-size: 0.9rem;
  background-color: rgb(255, 255, 255);
  padding: 4px 8px;
  max-width: 300px;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 15px -6px;
  cursor: pointer;
  font-family: inherit;
  margin-left: 4px;
  margin-right: 4px;
}
.matches-row.warning {
  background-color: #ffff0021;
}
.booked-button.booked-button-active {
  background-color: #0a8a95;
  color: white;
}

@media only screen and (max-width: 1175px) {
.matches-row {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;
  border-top: 1px solid black;
}
.header-row {
  display: none;
}
  /*
  .date-cell {
    width: 100px;
  }
  .name-cell {
    width: 110px;
  }
  .email-cell {
    width: 150px;
    overflow-wrap: anywhere;
  }
  .header-row > .booked-cell {
    display: none;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  */
}

@media only screen and (max-width: 560px) {
  .dashboard-sub-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .date-cell {
    width: 55px;
  }
  .name-cell {
    width: 70px;
  }
  .email-cell {
    width: 160px;
    overflow-wrap: anywhere;
  }
  .header-row > .booked-cell {
    display: none;
  }
  .break {
    flex-basis: 100%;
    height: 0;
  }
  .booked-cell {
    text-align: center;
  }
}

@media only screen and (max-width: 400px) {
  .email-cell {
    width: 100px;
  }
}

.nav-active {
  border-bottom: 1px solid #6a6868;
}

.profile-active {
  color: green;
}
.profile-inactive {
  color: red;
}

.react-calendar__tile--active {
  color: black !important;
  background-color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: black !important;
}
.react-calendar__tile--now {
  background-color: white;
}
.myClassName {
  position: relative;
  color: gray !important;
}

.myClassName:after {
  content: '';
  position: absolute;
  border-top: 1px solid gray;
  width: 20px;
  transform: translateY(22px) translateX(-80%);
}

.react-calendar {
  width: 600px;
  line-height: 3em;
  border: none;
}
.react-calendar button {
  font-weight: 600;
}
span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
  font-weight: bold;
}
abbr[data-original-title],
abbr[title] {
  text-decoration: none;
}

td {
  border: 1px solid black;
}

.calendar-question {
  background: white;
  width: 100%;
  display: flex;
  padding: 2px 8px;
  border: 2px solid #80808085;
  cursor: pointer;
  column-gap: 16px;
  justify-content: space-between;
}

.calendar-answer {
  background: white;
  width: 100%;
  display: flex;
  padding: 2px 8px;

}

button.calendar-sync-button {
  width: 192px;
  height: 40px;
  background: white;
  border-radius: 28px;
  border: 1px solid #000000;
}

textarea.calendar-notes {
  background: #F4F8F9;
  border: 1px solid #DFE7E9;
  border-radius: 6px;
  width: 100%;
  height: 108px;
  padding: 10px 16px;
  gap: 8px;
  margin-bottom: 16px;
}

.calendar-sync-pane {
  width: 700px;
  max-width: 100%;
  margin-bottom: 36px;
}