.result-card {
    border-radius: 12px;
    border: 1px solid #D9EBEF;
    background: #FFF;
    width: 839px;
    height: 319px;
    display: grid;
    grid-template-columns:
    300px 1fr
    1fr;
    font-family: 'Poppins', sans-serif;
    padding: 14px;
    padding-right: 14px;
    margin-bottom: 24px;
    column-gap: 6px;
}

.result-card-desktop {
    scale: 1.4;
    margin-bottom: 160px;
    margin-top: 80px;
}

.avatar {
    border-radius: 50%;
    position: relative;
    width: 100px;
    height: 70px;
    padding: 3px;
    text-align: center;
    background-color: #fff;
    margin-left: 10px;
    margin-right: 16px;
}

.avatar>img {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.result-card .header {
    width: 200px;
    display: flex;
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.result-card .subheader {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    margin-bottom: 0px;
}

.result-top {
    display: grid;
    grid-template-columns: 70px 1fr;
    margin-bottom: 9px;
}

.result-top-right {
    justify-content: space-between;
    width: 100%;
}

.rank {
    width: 48px;
    height: 48px;
    padding: 10px;
    padding-top: 13px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: #F3F7F8;
    color: #0A8A95;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.result-top-top-right-right {
    display: flex;
    width: 120px;
    justify-content: space-between;
}

.image-scroller {
    width: 100%;
    height: 234px;
    overflow-x: scroll;
    display: flex;
    overflow-y: hidden;
    justify-content: flex-start;
}

.image-scroller > div {
    position: relative;
    margin-bottom: 8px;
    margin-right: 5px;
    flex: 0 0 auto;
}
.image-scroller img {
    height: 100%;
    width: auto;
    border-radius: 6px;
    object-fit: cover;
}

.result-card-left {
    height: 234px;
}

.bottom {
    grid-column-start: 1;
    grid-column-end: 3;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.image-scroller::-webkit-scrollbar {
    height: 8px;
  }

  /* Track */
  .image-scroller::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, .1);
  }

  /* Handle */
  .image-scroller::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
  }

  .stat-bar {
    position: relative;
    margin-bottom: 20px;
    border-radius: 3px;
}

  .stat-bar-background {
    width: 100%;
    height: 4px;
    background: #CFEAEC;
    position: absolute;
    border-radius: 3px;
}

.stat-bar-bar {
    height: 4px;
    background: #2798A1;
    position: absolute;
    border-radius: 3px;
}

.stats {
    width: 260px;
    height: 158px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #ECF0F1;
    background: #FFF;
    margin-top: 4px;
}

.header-small {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
}

.stat-name {
    text-transform: capitalize;
    margin-bottom: 3px;
}
.stat-bar-circle {
    width: 8px;
    height: 8px;
    background: #2798A1;
    position: absolute;
    top: -2px;
    border-radius: 50%;
}

.stat-text-wrapper {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-top: 6px;
}

.result-middle {
    display: flex;
    column-gap: 8px;
}

.quote-box {
    width: 345px;
    height: 158px;
    border-radius: 6px;
    border: 1px solid #ECF0F1;
    padding: 10px;
    padding-top: 7px;
    margin-top: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.wedding-link {
    color: #0A8A95;
    text-decoration: underline;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 500;

}

.wedding-experience {
    color: #0A8A95;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 500;
    display: inline-flex;
    padding: 3px 5px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #E4F1F2;
    /* height: 20px; */
}

.quote-body {
    color: #788484;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 14px;
    overflow: hidden;
}

.quote-arrows {
    /* line-break: strict; */
    width: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.quote-author {
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 500;
}

.quote-date {
    color: #788484;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
}

.stat-score-wrapper {
    color: #788484;
    text-align: right;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
}

.stat-out-of {
    font-weight: normal;
    font-size: 12px;
}

.results-page {
    margin: auto;
    width: 839px;
}
.flex-between {
    display: flex;
    justify-content: space-between;
}

.quote-badge {
    color: #0A8A95;
    font-family: 'Poppins', sans-serif;
    font-size: 11px;
    font-weight: 600;
    border-radius: 4px;
    background: #C5E9ED;
    display: inline-flex;
    padding: 4px 6px;
    align-items: center;
    gap: 2px;
}

button.quote-arrow {
    color: #0A8A95;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #F3F7F8;
    width: 28px;
    height: 28px;
    font-size: 16px;
    cursor: pointer;
    font-family: ui-monospace;
}

button.quote-arrow:disabled, button.quote-arrow[disabled] {
    opacity: 0.3;
    color: gray;
}

.result-card-mobile {
    display: none;
}

.no-reviews {
    color: #CCD7DA;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-style: italic;
    margin-top: 40px;
    padding-left: 12px;
}

.percentage-bar {
    height: 4px;
    padding-right: 10px;
    position: absolute;
    background: #2798A1;
    border-radius: 3px;
    margin-bottom: 6px;
}

.percentage-bar-background {
    height: 4px;
    background: #CFEAEC;
    width: 100%;
    position: absolute;
    border-radius: 3px;
}

.results-header-new {
    margin-top: 24px;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
}

.results-subheader-new {
    color: #769597;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    margin-bottom: 12px;
}

.verified-badge {
    margin-left: 3px;
}

.share-button-new {
    border-radius: 56px;
    background: #0A8A95;
    display: inline-flex;
    padding: 10px 28px;
    align-items: flex-start;
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 600;
    width: 186px;
}

.result-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -155px;
}

.percent-match-wrapper {
    position: relative;
    width: 60px;
    text-align: left;
}

.social {
    display: flex;
    justify-content: end;
    margin-top: 12px;
}

button.round-button {
    display: flex;
    padding: 8px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 28px;
    border: 1px solid #0A8A95;
}

button.social-button {
    display: flex;
    padding: 8px 16px;
    align-items: flex-start;
    gap: 8px;
    border-radius: 28px;
    border: 1px solid #0A8A95;
    color: #0A8A95;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-left: 12px;
}

.social-button.inverted {
    color: white;
    background: #0A8A95;
    border: none;
}

img.mirror {
    transform: scaleX(-1);
}

.token {
    padding: 3px 5px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    color: #A7A7A7;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 500;
    display: inline-flex;
}

.bottom-left {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 8px;
}

.bottom-left.mobile {
    flex-direction: column;
    row-gap: 6px;
    margin-top: 10px;
}
.budget-token {
    background: #F3F3F3;
    color: #6d6d6d;
}

.calendar-confirmed-token {
    color: #0A8A95;
    background: #E4F1F2;
}

.calendar-updated-token {
    background: #EEE;
    color: #6d6d6d;
}

.result-token-wrapper {
    display: flex;
    column-gap: 5px;
    flex-wrap: wrap;
    row-gap: 4px;
}


@media only screen and (max-width: 875px) {

    .result-top-wrapper {
        display: block;
        margin-top: 12px;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 0px;
    }

    .result-token-wrapper {
        flex-direction: column;
    }

    .results-header-new, .results-subheader-new {
        text-align: center;
    }
    .results-header-new {
        font-size: 18px;
    }
    .results-subheader-new {
        font-size: 14px;
    }
    .share-button-new {
        width: 100%;
        text-align: center;
        display: unset;
    }
    .result-card-desktop {
        display: none;
    }
    .result-card-mobile {
        display: block;
    }

    .results-page {
        width: 100%;
    }
    .result-card {
        width: 100%;
        height: unset;
    }
    .panes {
        padding: 16px;
    }

    .quote-box {
        width: unset;
        border: none;
    }

    .stats {
        width: unset;
        border: none;
        margin: unset;
    }

    .avatar {
        width: 50px;
        height: 50px;
        margin-left: 0px;
    }
    .avatar > img {
        width: 50px;
        height: 50px;
    }

    .result-card .header {
        font-size: 14px;
    }
    .social {
        justify-content: center;
    }

}