.dropzone_and_notes {
    min-height: 462px;
    background: #F8F8F8;
    border: 1px dashed #0A8A95;
    box-sizing: border-box;
}

.dropzone_img_container_left {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(177, 177, 177, 0.35);
    transform: rotate(-19deg) !important;
}

.dropzone_img_container_right {
    background: #FFFFFF;
    box-shadow: 0px 0px 4px 1px rgba(177, 177, 177, 0.35);
    transform: rotate(19deg) !important;
} 

.dropzone_notes {
    margin-top: 40px;
    max-width: 494px;
    position:relative;
    padding:45px 0;
    border-top: 1px solid #D2E9EB;
    border-bottom: 1px solid #D2E9EB;
}

.little_note_box {
    top: -11px;
    left: 50%;
    margin-left: -32.5px;
    padding: 3px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #0A8A95;
    background: #FFFFFF;
    border: 1px solid #D2E9EB;
    box-sizing: border-box;
}

.add_photos_btn {
    cursor: pointer;
    display: block;
    padding: 11px 15px;
    background: #0A8A95;
    margin: 40px auto 0 auto;
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

.progress_box {
    background: #FFFFFF;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgba(177, 177, 177, 0.35);
    box-sizing: border-box;
}

.progress_bar_section {
    margin-top: 60px;
    width: 100%;
    max-width: 800px;
}

.circle-29 {
    height: 29px;
    width: 29px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.pause_bg {
    margin-right: 15px;
    background-color: #E5E5E5 !important;
}
.cancel_bg {
    background-color: #FFD1D1 !important;
}

.progress_bg {
    margin-top: 15px;
    width: 100%;
    background: #ECF5F5;
    height: 10px;
    border-radius: 100px;
}

.current_progress {
    position: absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    transition: all 0.5s;
}

.images_list_container {
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    margin-top:60px;
}

.individual_img_box {
    width: 100%;
    max-width: 162px;
    height: 185px;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

@media (min-width:992px) {  

    
    .images_list_container {
        gap: 31px;
    }

    .individual_img_box {
        width: 100%;
        min-width: 202px;
        max-width: 225px;
        height: 225px;
    }   
}
.img_upload_overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:rgba(0, 0, 0, 0.55)
}
.img_upload_overlay_delete {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.4;
    background-color:#C4C4C4
}

.cancel_btn {
    cursor: pointer;
    padding: 11px 15px;
    font-weight: 600;
    font-size: 15px;
    background: none !important;
    border: none !important;
    color: rgba(27, 45, 73, 0.8);
}

.retry_btn {
    cursor: pointer;
    padding: 11px 15px;
    font-weight: 600;
    font-size: 15px;
    background: #1B2D49;
    border: none !important;
    color: white;
}

.max_photos_warning {
    margin-top: 50px;
    padding: 20px 22px;
    background: #FFEFEF;
    border-left: 2px solid var(--warning);
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #FF2626;
}

.add_more_btn {
    padding: 15px 20px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    background: #0A8A95;
    border: none;
}

.check_box_unfilled {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border: 1px solid #0A8A95;
    box-sizing: border-box;
}

.check_box_filled {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    background: #0A8A95;
}

.publish-button {
    padding: 11px 15px;
    background: #0A8A95;
    margin: 40px auto 0 auto;
    border: none;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
}

.publish-button:disabled {
    background: #BBC5C6;
}