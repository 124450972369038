/* top level of all components */
.container-app {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
    min-height: 100%;
    font-family: "Georgia, serif" !important;
    -webkit-font-family: "Georgia, serif" !important;
}
.navbar-app {
    flex: 0;
    z-index: 1;
}
.logo-container {
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    height: 60px;
}
@media screen and (max-width: 500px) {
    .logo-container {
        height: 50px;
        margin-top: 10px;
    }
}

.sub-container {
    flex: 1;
    font-family: "Georgia" !important;
    padding: 1.75rem .8rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 800px;
    overflow-x: hidden !important;
    text-align: center;
}

.block {
    padding: 20px 0px;
}

.block-1-heading {
    font-family: Georgia, "Times New Roman", Times, serif;
    font-weight: bold;
    font-size: 25px;
    color: #656568;
    padding-bottom: 5px;
}

.block-1-text {
    color: #77777c;
    font-size: 15px;
}

.block-2-heading {
    color: #4a4949;
    font-size: 17px;
    font-weight: bold;
    padding-bottom: 15px;
}

.block-2-text {
    padding-bottom: 20px;
    color: #6a6868;
}

.block-3-text {
    color: #0e585e;
    font-size: 19px;
}

.block-3-link {
    color: #0e585e;
    font-size: 19px;
    cursor: pointer;
}

.block-3-link:hover {
    color: #0e585e !important;
}

/* PRIORITIES PAGE */
.MuiSlider-thumb.Mui-disabled {
   display: none;
}

.disabled-outline {
    opacity: .5;
}

.checkbox-container {
    position: relative;
    cursor: pointer;
    font-size: 20px;
    height: 28px;
    width: 28px;
    transform: translateY(-2px);
}



/* Hide the browser's default checkbox */
.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #617DC612;
    border: 1px solid black;
    border-radius: 2px;
    transform: translate(-10%, 10%);
}

.email-checkmark {
    border-radius: 8px;
    border: 1px solid #939191;
}

.email-checkmark {
    background-color: white;
}

/* When the checkbox is checked, add a blue background */


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid black;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container .email-checkmark:after {
    left: 8px;
    top: 4px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.checkbox-container input:checked ~ .email-checkmark {
  background-color: #0a8a95;
  border: 1px solid #0a8a95;
  border-radius: 8px;
}


.no-margin-bottom {
    padding-bottom: 5px;
}

.footer {
    flex: 0;
    font-family: "Georgia" !important;
}

/* LARGER SCREENS */
@media screen and (min-width: 500px) {
    .constiner-app {
        background-color: #f4f6fb !important ;
        background: #f4f6fb !important  ;
    }

    .sub-container-heading {
        padding: 40px 0 35px;
        margin: 0;
    }
    .sub-container-content {
        margin: 0 5px;
        padding-bottom: 25px !important;
    }
    .do-together-content {
        margin: 0;
    }
}

/* FOR IMAGES */
.left,
.right {
    transition: transform 0.2s linear !important;
}

.left:hover,
.right:hover {
    transform: scale(1.01) !important;
}

.disappear {
    opacity: 0;
}

.animate-0 {
    z-index: -1;
    display: block;
    position: fixed !important;
    transform-origin: right top;
    right: 50%;
    max-height: 100%;
    max-width: 45%;
    animation: animate-out-keyframes-0 0.25s ease forwards;
}

.animate-1 {
    z-index: -1;
    display: block;
    position: fixed !important;
    transform-origin: left top;
    left: 50%;
    max-height: 100%;
    max-width: 45%;
    animation: animate-out-keyframes-1 0.25s ease forwards;
}

/* BIG SCREEN */
@keyframes animate-out-keyframes-0 {
    0% {
        opacity: 1;
        transform: translate(0px, 0px) scale(1);
    }
    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(5px, -110px) scale(0);
    }
}
@keyframes animate-out-keyframes-1 {
    0% {
        opacity: 1;
        transform: translate(0px, 0px) scale(1);
    }
    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate(-5px, -110px) scale(0);
    }
}

/* MOBILE */

@media screen and (max-width: 500px) {
    .animate-0 {
        transform-origin: center top !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-height: 45%;
        max-width: 90%;
        animation: animate-out-keyframes-0 0.25s ease forwards;
    }

    .animate-1 {
        transform-origin: center top !important;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        max-height: 45%;
        max-width: 90%;
        animation: animate-out-keyframes-01 0.25s ease-in forwards;
    }

    @keyframes animate-out-keyframes-0 {
        0% {
            opacity: 1;
            transform: translateY(0) scale(1);
        }
        80% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: translateY(-90px) scale(0);
        }
    }
    @keyframes animate-out-keyframes-01 {
        0% {
            opacity: 1;
            transform: translateY(0%) scale(1);
        }
        80% {
            opacity: 1;
        }

        100% {
            opacity: 0;
            transform: translateY(-50%) scale(0);
        }
    }
}

/* PULSING */
.heart {
    animation: heart-pulse 0.3s 1 ease-in;
}

@keyframes heart-pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.13);
    }
    70% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.logo {
    height: 40px;
    padding: 1.5px;
    margin: 0;
}

.big-logo {
    height: 47px;
}

@media screen and (max-width: 500px) {
    .logo {
        height: 30px;
        padding: 1px;
    }
    .big-logo {
        height: 35px;
    }
}

.images-page {
    overflow: hidden;
    flex: 1;
}
